import React, { FC, useContext, useEffect, useState } from "react";
import { sc, useSitecoreContext } from "~/foundation/Jss";
import { HeaderProps } from "../generated-types";
import { useInView } from "react-intersection-observer";
import { Ribbon } from "~/foundation/Components/Ribbon";
import { ContentWrapper } from "~/foundation/Components/ContentWrapper";
import { Box, Flex, Link, useBreakpointValue } from "@chakra-ui/react";
import { HeaderStyles } from "./styles";
import { MotionBox } from "~/foundation/Framer/MotionBox";
import { Search } from "~/feature/Navigation/MainNavigation/Search";
import { useEventEmitter } from "~/foundation/Events";
import { NavigationEvents } from "~/feature/Navigation/events";
import openNavLogo from "~/foundation/Assets/svg/logos/logo-light.svg";
import { ColorThemeContext } from "~/foundation/Theme/ColorThemeContext";
import { withNavigationTheme } from "~/foundation/Theme/enhancers/withNavigationTheme";
import { useTranslation } from "~/foundation/Dictionary";
import { NavigationDictionary } from "~/feature/Navigation/dictionary";
import { GlobalContacts } from "~/foundation/Components/GlobalContacts";

let isLoaded = false;

const Header: FC<HeaderProps> = ({ rendering }) => {
	const [scrollingUp, setScrollingUp] = useState(false);
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [hideNavigation, setHideNavigation] = useState(false);
	const [t] = useTranslation<NavigationDictionary>();
	const eventEmitter = useEventEmitter<NavigationEvents>("navigation");
	const { sitecoreContext } = useSitecoreContext();

	const isLargeViewport = useBreakpointValue({ base: false, lg: true }, { ssr: true });

	const [theme] = useContext(ColorThemeContext);

	const { Logo } = theme;

	useEffect(() => {
		isLoaded = true;

		window.IG_HIDE_NAVIGATION = () => {
			setHideNavigation(true);
		}

		window.IG_SHOW_NAVIGATION = () => {
			setHideNavigation(false);
		}

		const subscription$ = eventEmitter.on("onNavigationOpen", opening => {
			setIsNavOpen(opening);
		});

		return () => subscription$.off();
	}, []);

	useEffect(() => {
		document.body.classList.toggle("header--hidden", !scrollingUp)

		const threshold = 0;
		let lastScrollY = window.scrollY;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.scrollY;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}

			setScrollingUp(scrollY <= lastScrollY);
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollingUp]);

	const { ref, inView } = useInView({
		threshold: 0.2,
		trackVisibility: true,
		delay: 100
	});

	const motionVariants = {
		scrolledTop: {
			transform: "translateY(0%)",
		},
		open: {
			transform: isLargeViewport ? "translateY(-70px)" : "translateY(0%)",
		},
		closed: {
			transform: isLargeViewport ? "translateY(-141px)" : "translateY(-100%)",
		},
	}

	const transition = {
		default: {
			ease: [.35,1,.45,1],
			duration: 1,
		}
	}

	// On load variants and transition for framer motion
	const loadingVariants = {
		initial: {
			opacity: 0,
		},
		active: {
			opacity: 1,
		}
	}

	const loadingTransition = {
		default: {
			ease: [.35,1,.45,1],
			duration: 1,
			delay: .325
		}
	}


	return (
		<>
			<Box position="absolute" h="50px" w="1px" opacity="0" ref={ref} />
			<Ribbon className="aramcocom__header" bgColor="transparent" as="header" {...HeaderStyles(theme)} >

				{isLargeViewport &&
					<MotionBox
						animate={hideNavigation ? { opacity: 0 } : { opacity: 1 }}
						transition={{
							ease: "linear",
							duration: .25
						}}
						bg="gradients.hygieneGlobalContacts"
						py={2}

					>
						<ContentWrapper
							pt="4"
							py={["4", null, null, "0"]}
							ps={['4', null, "4", null, '32']}
							pe={['4', null, "4", null, '16']}
							position="relative"
							zIndex="1"
						>
							<sc.Placeholder name={rendering.placeholderNames.header_hygiene_navigation} rendering={rendering} />
						</ContentWrapper>
					</MotionBox>
				}
				<MotionBox
					variants={loadingVariants}
					transition={loadingTransition}
					initial={loadingVariants.initial}
					animate={isLoaded ? loadingVariants.active : loadingVariants.initial}
				>
					<MotionBox
						animate={hideNavigation ? "closed" : (inView ? "scrolledTop" : (scrollingUp ? "open" : "closed"))}
						variants={motionVariants}
						initial="scrolledTop"
						transition={transition}
						w="100%"
						pb={[null, null, null, "1px"]}
						className={`aramcocom__header--inner${inView ? " scrolled-top" : ""}`}
					>
						<Box className="aramcocom__header-bg" position="absolute" bottom="0" left="0" right="0" height="200%" zIndex="-1" />
						<ContentWrapper py={["0", null]} px={['var(--chakra-sizes-pageMarginSM)', null, "8", null, '16']}>
							<Flex justifyContent="space-between" alignItems={["center", null, null, "initial"]} h={["headerHeightBase", null, null, "initial"]}>
								<Flex alignItems={["center", null, null, "normal"]}>
									{isLargeViewport &&
										<Flex flexDir="column" justifyContent="flex-end" alignItems="flex-start" mb=".75rem" me={[null, null, "1.6rem", null, "2.6rem"]}>
											<Search mobileView={false} />
										</Flex>
									}
									<Flex gap={6} alignItems="center">
										<sc.Placeholder name={rendering.placeholderNames.header_main_navigation} rendering={rendering} />
										{!isLargeViewport && (
											<Box mt={3}>
												<GlobalContacts />
											</Box>
										)}
									</Flex>
								</Flex>
								<Flex alignItems="flex-end" mb={[null, null, null, "1.625rem"]}>
									<Link href={`/${sitecoreContext.language}`} title={t("navigation.logoTitle")}>
										<Box w="7.65rem" h="1.575rem">
											{isNavOpen ? <img src={openNavLogo} alt="aramco logo" width="123" height="25" /> : <Logo />}
										</Box>
									</Link>
								</Flex>
							</Flex>
						</ContentWrapper>
					</MotionBox>
				</MotionBox>
			</Ribbon>
		</>
	);
};
export default withNavigationTheme({ fallbackToPageTheme: true })(Header);